import React, { useEffect, useState } from 'react';
import '../GlobalStyle.css';
import { GetOrderDetailsAPI } from '../../../services/Auth';
import moment from 'moment/moment';
import { useHistory, useLocation } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import copy from 'copy-to-clipboard';
import SuccessLoader from '../../SkeltonLoaders/SuccessLoader';
import { createParamsData } from '../../../services/params';
import { decryptText, getParamValue } from '../Auth/Login';

function Confirmation(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const history = useHistory();
  const location = useLocation();
  let paramsValue = createParamsData(location.search);

  const encryptedHash = paramsValue['hash'];
  const decryptedHash = encryptedHash ? decryptText(encryptedHash) : null;
  const newParams = createParamsData(decryptedHash);

  const ordersFunction = (order_id) => {
    GetOrderDetailsAPI(order_id)
      // GetOrderDetailsAPI('01JCJEMAAPV0HN2ZSEMY08SMRJ')
      .then((res) => {
        setOrderData(res.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (props?.location?.state?.order_id) {
      setIsLoading(true);
      ordersFunction(props.location.state.order_id);
    }
  }, [props?.location?.state?.order_id]);

  useEffect(() => {
    if (getParamValue('webhook_wait', newParams, paramsValue) === 'yes') {
      let intervalId;
      let startTime = Date.now();
      if (props?.location?.state?.order_id) {
        ordersFunction(props.location.state.order_id);
        intervalId = setInterval(() => {
          const currentTime = Date.now();
          const elapsedTime = (currentTime - startTime) / 1000;
          if (elapsedTime >= 30 || orderData.webhook_wait === 'no') {
            if (orderData['redirect_page'] === 'self') {
              window.open(`${orderData['redirect_url']}`, '_self');
            } else if (orderData['redirect_page'] === 'parent') {
              window.parent.location.href = `${orderData['redirect_url']}`;
            } else {
              window.open(`${orderData['redirect_url']}`, '_black');
            }
            clearInterval(intervalId);
          } else {
            ordersFunction(props.location.state.order_id);
          }
        }, 5000);
      }

      return () => clearInterval(intervalId);
    }
  }, [props?.location?.state?.order_id, orderData.webhook_wait]);

  return (
    <div
      style={{
        background: '#f5f7fa',
        width: '100%',
        height: '100%',
        borderRadius: `24px`,
        padding: '35px'
      }}>
      {isLoading ? (
        <SuccessLoader />
      ) : (
        <>
          <div className="ticket">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div className="circle mt-2">
                <div>
                  <i
                    className="mdi mdi-checkbox-marked-circle font-size-28"
                    style={{ color: '#23a26d' }}
                  />
                </div>
              </div>
              <span className="text-black font-weight-bold font-size-22 mt-2">
                Payment Successful!
              </span>
            </div>
            <hr
              style={{
                border: 'none',
                borderTop: '1px dashed transparent',
                background: 'linear-gradient(to right, grey 30%, transparent 30%)',
                backgroundSize: '10px 1px',
                position: 'relative',
                top: '-15px'
              }}
              className="mx-5"
            />
            <div className="px-5">
              <div className="d-flex justify-content-between py-1">
                <span className="text-grey font-weight-medium font-size-16">
                  Order id
                </span>
                <span className="text-black font-weight-medium font-size-14">
                  {orderData.id ? extractFromAddress(orderData.id) : 'n/a'}
                </span>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span className="text-grey font-weight-medium font-size-16">Product</span>
                <span className="text-black font-weight-medium font-size-14">
                  {orderData.productData && orderData.productData.length > 0
                    ? orderData.productData[0].product_name
                    : 'n/a'}
                </span>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span className="text-grey font-weight-medium font-size-16">Address</span>
                <span
                  className="text-black font-weight-medium font-size-14 cursor-pointer"
                  onClick={() => {
                    if (orderData.address) {
                      copy(orderData.address);
                      toast.success('Address copied successfully', {
                        style: { fontSize: '12px' }
                      });
                    }
                  }}>
                  {orderData.address ? extractFromAddress(orderData.address) : 'n/a'}
                </span>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span className="text-grey font-weight-medium font-size-16">
                  Transaction id
                </span>
                <span
                  className="text-black font-weight-medium font-size-14 cursor-pointer"
                  onClick={() => {
                    if (orderData.transaction_id) {
                      window.open(
                        `https://${
                          process.env.REACT_APP_ENVIRONMENT === 'production'
                            ? ''
                            : 'amoy.'
                        }polygonscan.com/tx/${orderData.transaction_id}`
                      );
                    }
                  }}>
                  {orderData.transaction_id
                    ? extractFromAddress(orderData.transaction_id)
                    : 'n/a'}
                </span>
              </div>
              <div className="d-flex justify-content-between py-1">
                <span className="text-grey font-weight-medium font-size-16">
                  Payment time
                </span>
                <span className="text-black font-weight-medium font-size-14">
                  {orderData.created_at
                    ? moment(orderData.created_at).format('DD/MM/YYYY, hh:mm A')
                    : 'n/a'}
                </span>
              </div>
              <hr />
              <div className="d-flex justify-content-between py-1">
                <span className="text-grey font-weight-medium font-size-16">Amount</span>
                <span className="text-black font-weight-medium font-size-14">
                  {orderData.base_amount
                    ? `${orderData.base_amount} ${orderData.base.replace('SC', '')}`
                    : 'n/a'}
                </span>
              </div>
              {(!getParamValue('webhook_wait', newParams, paramsValue) ||
                getParamValue('webhook_wait', newParams, paramsValue) === 'no' ||
                getParamValue('webhook_wait', newParams, paramsValue) === null) &&
                (!orderData.webhook_wait || orderData.webhook_wait === 'no') && (
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <button
                      style={{
                        background: 'black',
                        height: '50px',
                        width: '300px'
                      }}
                      type="button"
                      className={'btn btn-block btn-lg text-capitalize'}
                      onClick={() => {
                        if (
                          orderData &&
                          (orderData.redirect_url === '' || !orderData.redirect_url)
                        ) {
                          history.push({
                            pathname: `${
                              props.location.state && props.location.state.return_path
                                ? props.location.state.return_path
                                : props.isWidget
                                ? '/buy-sell/buy-nft'
                                : '/buy-nft'
                            }`,
                            search: `${location.search}`
                          });
                        } else {
                          if (orderData.redirect_page === 'self') {
                            window.open(`${orderData.redirect_url}`, '_self');
                          } else if (orderData['redirect_page'] === 'parent') {
                            window.parent.location.href = `${orderData.redirect_url}`;
                          } else {
                            window.open(`${orderData.redirect_url}`, '_black');
                          }
                        }
                      }}>
                      <div className="text-white font-size-18">Done</div>
                    </button>
                  </div>
                )}
            </div>
          </div>
        </>
      )}

      <Toaster position="bottom-center" reverseOrder={false} />
    </div>
  );
}

export default Confirmation;

export function extractFromAddress(address) {
  if (address) {
    const firstSix = address.substring(0, 6);
    const lastFour = address.substring(address.length - 4);

    return `${firstSix}...${lastFour}`;
  }
}
